import { Show, createSignal } from "solid-js";
import {
  createAsync,
  type RouteDefinition,
  type RouteSectionProps,
  useSearchParams,
} from "@solidjs/router";
import { Meta } from "@solidjs/meta";
import { useProduct } from "~/services/products/useProduct";
import { PHOTOFRAME } from "~/utils/products";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { photoFrameSizes, pfSizesByCode } from "~/utils/builtFrames";
import {
  ProductHeader,
  SwatchDisplay,
  PFGFPricingBox,
  FrameCounter,
  ProductDescription,
  ProductDetails,
} from "~/components/product/product-page";
import { Accordion } from "~/components/ui";
import { Product } from "~/services/roma-api/products/types";
import { Permission } from "~/services/roma-api/account/types";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, {
      type: PHOTOFRAME,
      available_list: true,
      suggestions: true,
    }),
} satisfies RouteDefinition;

export default function PhotoFrame(props: RouteSectionProps) {
  const { isPartner, hasPermission } = useSessionContext();
  const { global } = useSiteContext();
  const [params, setParams] = useSearchParams<{ size: string }>();
  const [qty, setQty] = createSignal(2);
  const [pricing, setPricing] = createSignal();
  const product = createAsync(() =>
    useProduct(props.params.sku, {
      type: PHOTOFRAME,
      available_list: true,
      suggestions: true,
    })
  );

  const size = () => {
    if (isPartner() && !params.size) {
      // setParams({ size: 46 }, { replace: true });
      return photoFrameSizes[0].code;
    } else if (!params.size) {
      // ?why?
      return "46";
    }
    return params.size;
  };

  const frameSizeOptions = Object.entries(pfSizesByCode).map(([code, data]) => {
    return {
      value: code,
      label: (
        <span>
          {data.size[0]}
          <span class="text-xs mx-1">&#10005;</span>
          {data.size[1]}
        </span>
      ),
    };
  });

  return (
    <>
      <Show when={product() && global()}>
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader
            product={product}
            type={PHOTOFRAME}
            size={params.size}
          />
          <Show
            when={isPartner()}
            fallback={<SwatchDisplay product={product} type={PHOTOFRAME} />}
          >
            <PFGFPricingBox
              product={product}
              sizes={photoFrameSizes}
              sizeOptions={frameSizeOptions}
              activeSize={size}
              sku={props.params.sku}
              productType={PHOTOFRAME}
              setPricing={setPricing}
            />
            <Show when={hasPermission(Permission.PlaceOrder)}>
              <div class="grid grid-cols-2 sm:grid-cols-3 gap-x-2">
                <FrameCounter
                  minimum={2}
                  getCount={qty}
                  updateCount={setQty}
                  label="Quantity"
                />
              </div>
            </Show>
          </Show>
          <div class="bg-orange-50 border border-orange-500 text-orange-500">
            TODO: Buy/Favourite Buttons <br />
            Wait for cart/auth
          </div>
        </div>
        <ProductDescription description={product()?.Description as string} />
        <ProductDetails product={product} hideAvailableAs>
          <ul class="list-inside list-disc pb-3 text-sm text-roma-dark-grey pl-2">
            <li>Scratch Resistant Glass</li>
            <li>Durable easel backing</li>
            <li>Landscape or portrait display</li>
            <li>Custom made with love and care</li>
            <li>Solid wood frame made in Italy</li>
            <li>Assembled in the USA and Canada</li>
            <li>Durable and scratch resistant finish</li>
            <li>100% Happiness Guarantee</li>
          </ul>
        </ProductDetails>
        <Accordion
          name="care-instructions"
          label="Care Instructions"
          labelClass="font-bold"
          icon="Chevron"
          childClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
        >
          <p>
            Gently wipe your frame with a soft dry cloth. To remove spots or
            smudges from acrylic or glass, we recommend applying glass cleaner
            to a clean, soft, dry cloth or paper towel and then to the surface
            so that the cleaner does not settle on the frame, then buff the
            surface dry to remove any streaks.
          </p>
        </Accordion>
      </Show>
    </>
  );
}
